import axios from 'axios';
import store from '@/services/store';

// eslint-disable-next-line @typescript-eslint/no-extraneous-class
export abstract class HomeApi {
    private static oblCache: string | null = null;
    // Promise to track the ongoing request
    private static oblPromise: Promise<string> | null = null;

    static async getObl(): Promise<string> {
        if (this.oblCache !== null) {
            // Return cached value if it exists
            return this.oblCache;
        }

        if (this.oblPromise !== null) {
            // Wait for the ongoing request if it exists
            return this.oblPromise;
        }

        // Initiate the request and store the promise
        this.oblPromise = (async () => {
            try {
                const response = await fetch(
                    `/api-py/get-budget-obl/${store.state._instanceCode}`
                );
                const json = await response.json();
                this.oblCache = json.obl; // Cache the result
                return json.obl;
            } catch (error) {
                console.error("Error fetching budget obl:", error);
                throw error;
            } finally {
                // Clear the promise reference after completion
                this.oblPromise = null;
            }
        })();

        // Wait for the result of the ongoing request
        return this.oblPromise;
    }

    private static instanse = axios.create({
        headers: {
            'Access-Control-Allow-Origin': '*'
        }
    });
    // eslint-disable-next-line @typescript-eslint/explicit-member-accessibility
    static async getBp(): Promise<any> {
        const curYear = new Date().getFullYear();
        const params = { year: curYear, budgetLevel: [2], obl: store.state._instanceCode };
        const url = '/api-py/intercon-bp-ci/';
        // eslint-disable-next-line no-return-await
        return await this.instanse.post(url, JSON.stringify(params));
    }

    static async getBpFast(): Promise<any> {
        const curYear = new Date().getFullYear();
        const params = { year: curYear, budgetLevel: [2], obl: store.state._instanceCode };
        const url = '/api-py/intercon-bp-ci-fast/';
        // eslint-disable-next-line no-return-await
        return await this.instanse.post(url, JSON.stringify(params));
    }

    // eslint-disable-next-line @typescript-eslint/explicit-member-accessibility
    static async getCi(): Promise<any> {
        const curYear = new Date().getFullYear();
        const url = '/api/stat-dicts/target_indicator_count?year=' + curYear;
        // eslint-disable-next-line no-return-await
        return await this.instanse.get(url);
    }

    // eslint-disable-next-line @typescript-eslint/explicit-member-accessibility
    static async getSnp(): Promise<any> {
        const url = '/api/snp/widget';
        // eslint-disable-next-line no-return-await
        return await this.instanse.get(url);
    }

    // eslint-disable-next-line @typescript-eslint/explicit-member-accessibility
    static async getSnp2(): Promise<any> {
        const url = '/api/snp/widget-2';
        // eslint-disable-next-line no-return-await
        return await this.instanse.get(url);
    }

    // eslint-disable-next-line @typescript-eslint/explicit-member-accessibility
    static async getSnp3(): Promise<any> {
        const url = '/api/snp/widget-3';
        // eslint-disable-next-line no-return-await
        return await this.instanse.get(url);
    }

    // eslint-disable-next-line @typescript-eslint/explicit-member-accessibility
    static async getSnp4(): Promise<any> {
        const url = '/api/snp/widget-4';
        // eslint-disable-next-line no-return-await
        return await this.instanse.get(url);
    }

    // eslint-disable-next-line @typescript-eslint/explicit-member-accessibility
    static async getInvest(): Promise<any> {
        const url = '/api/widget/total';
        // eslint-disable-next-line no-return-await
        return await this.instanse.get(url);
    }
    static async getKat(): Promise<any> {
        const curYear = new Date().getFullYear();
        const url = `/api-py/get-budget-request-kat/${curYear}/${await this.getObl()}0101`;
        // eslint-disable-next-line no-return-await
        return await this.instanse.get(url);
    }

    // eslint-disable-next-line @typescript-eslint/explicit-member-accessibility
    static async getIncome(): Promise<any> {
        const date = new Date().getFullYear();
        const url = `/api-py/get-request-budg-doh/${date}/12/${await this.getObl()}0101`;
        // eslint-disable-next-line no-return-await
        return await this.instanse.get(url);
    }

    // eslint-disable-next-line @typescript-eslint/explicit-member-accessibility
    static async getOutcome(): Promise<any> {
        const date = new Date().getFullYear();
        const url = `/api-py/get-request-budg-rash/${date}/12/${await this.getObl()}0101`;
        // eslint-disable-next-line no-return-await
        return await this.instanse.get(url);
    }

    // eslint-disable-next-line @typescript-eslint/explicit-member-accessibility
    static async getBip(): Promise<any> {
        const url = '/api-py/bip-widget-one';
        // eslint-disable-next-line no-return-await
        return await this.instanse.get(url);
    }

    // eslint-disable-next-line @typescript-eslint/explicit-member-accessibility
    static async getBip2(): Promise<any> {
        const url = '/api-py/bip-widget-two';
        // eslint-disable-next-line no-return-await
        return await this.instanse.get(url);
    }

    // eslint-disable-next-line @typescript-eslint/explicit-member-accessibility
    static async getBip3(): Promise<any> {
        const url = '/api-py/budget-progs-sum-widget';
        // eslint-disable-next-line no-return-await
        return await this.instanse.get(url);
    }
}